.announcement-slider .swiper-button-prev {
    display: none;
}
.announcement-slider .swiper-button-next {
    display: none;
}
.product-detail-swiper .swiper-button-next {
    display: none;
}

.product-detail-swiper .swiper-button-prev {
    display: none;
}
.swiper-button-lock {
    display: block !important;
}
